<template>
    <div class="sidebar-nav-element" v-bind:class="{active: $store.state.active === 'videos'}" @click="setActiveNav('videos')">
        <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path class="st7" d="M31.4,8.6c-0.2-1.6-1.6-3-3.3-3.1c-9.8-0.7-14.6-0.7-24.3,0C2.2,5.6,0.8,7,0.6,8.6c-0.8,5.9-0.8,8.9,0,14.8
	c0.2,1.6,1.7,3,3.3,3.1C8.7,26.8,12.4,27,16,27s7.3-0.2,12.2-0.5c1.6-0.1,3-1.5,3.3-3.1C32.2,17.5,32.2,14.5,31.4,8.6z" fill="white"/>
<path class="st0" d="M21.1,17.7l-6,4C14.8,21.9,14.4,22,14,22c-0.3,0-0.7-0.1-0.9-0.2c-0.7-0.4-1.1-1-1.1-1.8V12
	c0-0.8,0.4-1.4,1.1-1.8c0.7-0.4,1.4-0.3,2.1,0.1l6,4c0.6,0.4,0.9,1,0.9,1.7S21.7,17.3,21.1,17.7z" fill="white"/>
</svg>
        <div class="label">Videos</div>
    </div>
</template>

<script>
export default {
    name: 'Videos',
    props: {},
    data() {
        return {}
    },
    methods: {
        setActiveNav(navElement) {
            this.$store.dispatch('set_active_nav', navElement)
        }
    }
}
</script>



<style scoped>
    .active {
        background-color: rgb(79,185,255);
        border-left: 5px solid white;        
    }

    .sidebar-nav-element {
        display: grid;
        grid-template-columns: 20px auto;
        color: white;
        font-weight: bold;
        text-align: left;
        padding-left: 24px;
        padding-top: 12px;
        cursor: pointer;
    }

    .label {
        padding-left: 38px;
        padding-top: 4px;
    }

    /* svg styles */
    .st0{fill: rgb(0,138,230);}
	/* .st1{fill:blue;}
	.st2{fill:#F4F4F4;} */
	/* .st3{fill:white;} */
	/* .st4{fill:#0063DB;} */
	/* .st5{fill:#00ACED;} */
	/* .st6{fill:#FFEC06;} */
	.st7{fill:white;}
	/* .st8{fill:#25D366;} */
	/* .st9{fill:#0088FF;}
	.st10{fill:#314358;}
	.st11{fill:#EE6996;}
	.st12{fill:#01AEF3;}
	.st13{fill:#FFFEFF;}
	.st14{fill:#F06A35;}
	.st15{fill:#00ADEF;}
	.st16{fill:#1769FF;}
	.st17{fill:#1AB7EA;}
	.st18{fill:#6001D1;}
	.st19{fill:#E41214;}
	.st20{fill:#05CE78;}
	.st21{fill:#7B519C;}
	.st22{fill:#FF4500;}
	.st23{fill:#00F076;}
	.st24{fill:#FFC900;}
	.st25{fill:#00D6FF;}
	.st26{fill:#FF3A44;}
	.st27{fill:#FF6A36;}
	.st28{fill:#0061FE;}
	.st29{fill:#F7981C;} */
	/* .st30{fill:white;} */
	/* .st31{fill:white;} */
	/* .st32{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;} */
	/* .st33{fill:#0097D3;}
	.st34{fill:#01308A;}
	.st35{fill:#019CDE;}
	.st36{fill:#FFD049;}
	.st37{fill:#16A05D;}
	.st38{fill:#4486F4;} */
	/* .st39{fill:none;}
	.st40{fill:#34A853;}
	.st41{fill:#4285F4;}
	.st42{fill:#FBBC05;} */
	/* .st43{fill:blue;} */
</style>